import en from '@/locales/en.json';
import da from '@/locales/da.json';

export default defineI18nConfig(() => {
  let reqLocale = ''
  if (process.server) {
    const nuxtApp = useNuxtApp()
    reqLocale = nuxtApp.ssrContext?.event.node.req.headers['accept-language']?.split(',')[0]
  }  else {
    reqLocale = navigator.language
  }



  return {
    legacy: false,
    locale: reqLocale,
    fallbackLocale: 'en',
      messages: {
      en,
      da,
    },
    warnHtmlMessage: false,
  };
});
